import { ErrorLogger } from '@edapp/monitoring';
import type { SlideType } from '@maggie/store/courseware/lessons/types';

import type {
  InteractionFromThomas,
  ThomasAICCInteraction,
  ThomasGameInteraction,
  ThomasSCORMInteraction,
  ThomasSlideInteraction
} from '../thomas/thomas-interaction-types';

export class SlideScoring {
  /**
   * This will calculate score of a slide based on the interaction.
   *
   * The logic is super funky! But it's hard to change the way it is now.
   *
   * It will look through the interaction payload and find the first key that intersects between
   * the `calculatorDictionary` and the interaction payload itself. Once that key is identified,
   * it will use the key to lookup the `calculatorDictionary` and calculate the score.
   */
  public static calculate = (interaction: InteractionFromThomas, weight: number): number => {
    const calculatorDictionary = {
      correct: this.getSlideScore,
      isScormAttempt: this.getPackageScore,
      isAiccAttempt: this.getPackageScore,
      game: this.getGameScore
    };

    // attempt to get a key from the interaction that matches our pre-defined interactionWeighs
    const key = Object.keys(interaction).find(key =>
      Object.keys(calculatorDictionary).find(type => key === type)
    );

    if (!key) {
      ErrorLogger.captureEvent('Calculating interaction that doesnt match dictionary', 'error', {
        interaction,
        weight
      });
      return 0;
    }

    // @ts-expect-error ThomasViewInteraction is missing some properties
    return calculatorDictionary[key as keyof typeof calculatorDictionary](interaction, weight);
  };

  public static getSlideWeight = (slide: SlideType): number => {
    return (
      slide?.data?.challenge_configuration?.weighting ||
      slide?.data?.challenge_configuration?.nb_stars ||
      0
    );
  };

  /**
   * Correct Interaction aka True/False interaction
   */
  private static getSlideScore = (interaction: ThomasSlideInteraction, weight: number): number => {
    return weight && interaction.correct ? weight : 0;
  };

  /**
   * Scorm or Aicc Attempts
   */
  private static getPackageScore = (
    interaction: ThomasAICCInteraction | ThomasSCORMInteraction,
    weight: number
  ): number => {
    const interactionScore =
      !isNaN(interaction.score) && isFinite(interaction.score) ? interaction.score : 0;
    return weight * interactionScore;
  };

  /**
   * Game interaction - add scaled score to weight of slide based on the number of questions/challenges
   */
  private static getGameScore = (interaction: ThomasGameInteraction, weight: number): number => {
    if (!weight || !interaction.score) {
      return 0;
    }

    const numCorrectQuestions = interaction.score.correct || 0;
    const numIncorrectQuestions = interaction.score.incorrect || 0;
    const numQuestions = numCorrectQuestions + numIncorrectQuestions;
    if (numQuestions === 0) {
      return 0;
    }
    const correctRatio = numCorrectQuestions / numQuestions;
    return weight * correctRatio;
  };
}
