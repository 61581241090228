import * as React from 'react';

import styled, { css } from 'styled-components';

import { mergeRefs } from '../../../utils';
import type { TextAreaProps } from './types';

export const TEXT_AREA_TEST_ID = 'text-area';

const TextAreaComponent: React.FC<TextAreaProps> = React.forwardRef(
  (
    {
      testId = TEXT_AREA_TEST_ID,
      hasMargin = true,
      className,
      disabled,
      isDisabled,
      highlightText,
      onChange,
      fitContents,
      value,
      ...rest
    },
    ref
  ) => {
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
    const mergedRef = React.useMemo(() => mergeRefs([ref, textAreaRef]), [ref, textAreaRef]);

    // automatically resize text area to fit its contents
    const resizeTextArea = React.useCallback(() => {
      const { current: textAreaEl } = textAreaRef;
      if (textAreaEl == null) {
        return;
      }

      if (!fitContents) {
        // reset height
        textAreaEl.style.height = '';
        return;
      }

      if (textAreaEl.scrollHeight) {
        textAreaEl.style.height = 'auto';
        textAreaEl.style.height = `${textAreaEl.scrollHeight + 2}px`; // +2px to account for border
      }
    }, [fitContents]);

    React.useEffect(() => {
      resizeTextArea();
    }, [value, fitContents, resizeTextArea]);

    // Autofocus logic
    React.useEffect(() => {
      if (textAreaRef.current && rest.autoFocus) {
        textAreaRef.current.focus();
        if (typeof value === 'string') {
          if (highlightText) {
            textAreaRef.current.setSelectionRange(0, value.length);
          } else {
            // Move cursor to the end of initial value
            textAreaRef.current.setSelectionRange(value.length, value.length);
          }
        }
      }
    }, [rest.autoFocus]);

    const internalDisabled = disabled || isDisabled;

    const handleChange = (changeEvent: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange?.(changeEvent);
      resizeTextArea();
    };

    return (
      <StyledTextArea
        ref={mergedRef}
        className={className}
        disabled={internalDisabled}
        isDisabled={internalDisabled}
        onChange={handleChange}
        hasMargin={hasMargin}
        fitContents={fitContents}
        data-testid={testId}
        value={value || ''}
        {...rest}
      />
    );
  }
);

const StyledTextArea = styled.textarea<TextAreaProps>(
  ({ theme, hasMargin, isResizable, isFullWidth, fitContents, error }) => css`
      color: ${theme.colors.text};
      padding: 13px ${theme.space(2)}px 11px;
      width: ${isFullWidth ? '100%' : 'auto'};
      border-radius: ${theme.sizes.borderRadius}px;
      border-color: ${theme.colors.lightGrey};
      ${hasMargin && `margin-bottom: ${theme.space(2)}px;`}
      transition: border-color ${theme.transition.slow};
      ${(fitContents || isResizable === false) && 'resize: none;'}
      ${fitContents && 'overflow: hidden;'}
      ${theme.typography.normal}
      border: 1px solid ${theme.colors.greyHover};
      border-color: ${error ? theme.colors.red : theme.colors.greyHover};
      display: inline-flex;
      flex-flow: row nowrap;
      align-items: center;
      outline: none;
      vertical-align: top;
      background-color: ${theme.colors.white};
      font-family: inherit;

      ::placeholder {
        color: ${theme.colors.textMuted};
      }

      :disabled {
        opacity: 0.3;
      }

      :enabled {
        &:hover {
          border-color: ${theme.colors.grey};
        }

        &:focus {
          border-color: ${theme.colors.blue};
        }
      }
    `
);

export const TextArea = styled(TextAreaComponent)``;
