import axios from 'axios';
import { isArray, isObject as lodashIsObject } from 'lodash-es';
import * as qs from 'qs';

import type { ErrorResponseType, ValidationErrorResponseType } from './errors';
import { ErrorCode } from './errors';
import { incorporateToken } from './token-strategies';
import type {
  EdExceptionType,
  ExtractErrorResult,
  FetchMethodType,
  HippoError,
  MediaUploadOptions,
  MediaUploadResponse,
  TokenParameter,
  UploadResponse
} from './types';

export const EdException = (
  message: string,
  response: Response,
  statusCode: number,
  url: string,
  requestBody?: {} | null,
  errorResponse?: ErrorResponseType | ValidationErrorResponseType
): EdExceptionType => ({
  message,
  response,
  statusCode,
  url,
  requestBody,
  errorResponse
});

const isNullOrUndefined = (value: any) => value === undefined || value === null;

export const DEFAULT_ERROR_MESSAGE = 'Failed!';

const stripOutUndefinedAndNull = (data: any) => {
  if (!data) {
    return null;
  }

  if (typeof data !== typeof {}) {
    return data;
  }

  const strippedData: Record<string, unknown> = {};
  Object.keys(data).forEach(key => {
    const value = data[key];
    if (!isNullOrUndefined(value)) {
      strippedData[key] = value;
    }
  });
  return strippedData;
};

export const getUrlWithEncodedParams = (
  url: string,
  params: Record<string, string | number | boolean | string[]>
) => {
  const encodedParams = Object.keys(params)
    .filter(key => !isNullOrUndefined(params[key]))
    .map(key => {
      if (Array.isArray(params[key])) {
        return params[key]
          .map((v: string) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
          .join('&');
      }

      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    })
    .join('&');

  return `${url}?${encodedParams}`;
};

const isObject = (prop: any) => lodashIsObject(prop) && !isArray(prop);

export const getNestedFormData = (params: Record<string, unknown>, prefix: string): string => {
  return Object.keys(params)
    .filter(key => !isNullOrUndefined(params[key]))
    .map(key => {
      const prop = params[key];
      const newPrefix = !!prefix ? `${prefix}.${key}` : `${key}`;

      if (isObject(prop)) {
        return getNestedFormData(prop as Record<string, unknown>, newPrefix);
      }

      if (Array.isArray(prop)) {
        if (prop.length === 0) {
          return '';
        }

        const arrayPrefix = `${newPrefix}[]`;
        return prop
          .map((v: any) => {
            return isObject(v)
              ? getNestedFormData(v, arrayPrefix)
              : `${arrayPrefix}=${encodeURIComponent(v)}`;
          })
          .join('&');
      }

      return `${newPrefix}=${encodeURIComponent(prop as string | number | boolean)}`;
    })
    .filter(v => !!v)
    .join('&');
};

export const getEndpointUrl = (url: string, uri: string | undefined): string => {
  if (!uri) {
    throw Error(`Invalid uri '${uri}'...`);
  }

  const cleanUri = uri.indexOf('/') === 0 ? uri.slice(1) : uri;
  return `${url}/${cleanUri}`;
};

export async function httpFetch<ParsedResponse, Payload = undefined>(
  method: FetchMethodType,
  url: string,
  userToken: TokenParameter,
  bodyData?: Payload,
  credentials?: RequestCredentials,
  headers?: HeadersInit,
  isUrlEncodedFormData?: boolean,
  locale?: string,
  uriEncodeData = false,
  abortSignal?: AbortSignal,
  isMultipartFormData: boolean = false
): Promise<ParsedResponse> {
  const isClientSide = typeof window !== 'undefined' && typeof document !== 'undefined';

  const defaultNonMultipartFormDataHeaders = {
    'Content-Type': isUrlEncodedFormData
      ? 'application/x-www-form-urlencoded; charset=UTF-8'
      : 'application/json; charset=utf-8'
  };
  const overridableHeaders = {
    Accept: 'application/json',
    ...(!isMultipartFormData && defaultNonMultipartFormDataHeaders),
    ...headers
  };
  const encapsulatedHeaders = new Headers(overridableHeaders);
  const fallback = 'en';

  if (locale) {
    encapsulatedHeaders.set('locale', locale);
  } else if (typeof window !== 'undefined') {
    encapsulatedHeaders.set(
      'locale',
      !!navigator?.languages?.length ? navigator.languages[0] : navigator.language || fallback
    );
  } else {
    encapsulatedHeaders.set('locale', fallback);
  }

  const g = globalThis || window;
  if (!g?.__disableEdAppTokens) {
    incorporateToken(userToken, encapsulatedHeaders);
  }

  const options: RequestInit = {
    method,
    credentials,
    headers: encapsulatedHeaders,
    signal: abortSignal
  };

  if (bodyData) {
    if (method === 'GET') {
      const bodyParams = stripOutUndefinedAndNull(bodyData);
      const queryParams = qs.stringify(bodyParams, { encode: uriEncodeData });
      url += `?${queryParams}`;
    } else {
      options.body = !!isMultipartFormData
        ? (bodyData as unknown as FormData)
        : !!isUrlEncodedFormData
          ? getNestedFormData(bodyData, '')
          : JSON.stringify(Array.isArray(bodyData) ? bodyData : stripOutUndefinedAndNull(bodyData));
    }
  }

  return fetch(url, options)
    .then(async (response: Response) => {
      if (response.ok && response.status === 200 && isClientSide) {
        const event = new window.CustomEvent('training-available', {
          detail: { url, response, options }
        });
        window.dispatchEvent(event);
      } else if (!response.ok && response.status === 503 && isClientSide) {
        const event = new window.CustomEvent('training-unavailable', {
          detail: { url, response, options }
        });
        window.dispatchEvent(event);
      }

      if (!response.ok) {
        // This allows Backbone code to know when the session is expired and
        // when it should show a modal to refresh a users session
        if (response.status === 401 && isClientSide) {
          const event = new window.CustomEvent('api-unauthorized', {
            detail: { url, response, options }
          });
          window.dispatchEvent(event);
        }
        // this is the default error
        let errorResponse: ErrorResponseType | ValidationErrorResponseType = {
          type: 'Error',
          message: DEFAULT_ERROR_MESSAGE,
          code: response.status === 401 ? ErrorCode.AuthenticationFailed : ErrorCode.NotSpecified,
          statusCode: response.status
        };
        try {
          const responseText = await response.text();
          const json = JSON.parse(responseText);
          if (json && typeof json === 'object') {
            errorResponse = json;
            // this error logic is coming from back-end
            // Hippo.Api -> ErrorHandlerMiddleware.cs
            errorResponse.type = 'validationErrors' in errorResponse ? 'ValidationError' : 'Error';
          } else {
            errorResponse.message = responseText;
          }
        } catch (err) {
          // ignore
        }

        throw EdException(
          `${options.method} request to ${url} resulted in a ${response.status} HTTP status code.`,
          response,
          response.status,
          url,
          options.body,
          errorResponse
        );
      }

      return response.text();
    })
    .then((text: string) => {
      let parsedResponse: ParsedResponse;
      try {
        parsedResponse = text ? JSON.parse(text) : {};
      } catch (e) {
        throw new Error(`Format of the response of ${url} is invalid. ${JSON.stringify(e)}`);
      }
      return parsedResponse;
    });
}

export async function httpFetchDownload(
  url: string,
  userToken: TokenParameter,
  credentials?: RequestCredentials,
  headers?: HeadersInit,
  locale?: string,
  abortSignal?: AbortSignal
): Promise<void> {
  const isClientSide = typeof window !== 'undefined' && typeof document !== 'undefined';

  const overridableHeaders = {
    Accept: 'application/json',
    ...headers
  };
  const encapsulatedHeaders = new Headers(overridableHeaders);

  if (locale) {
    encapsulatedHeaders.set('locale', locale);
  } else {
    encapsulatedHeaders.set(
      'locale',
      !!navigator?.languages?.length ? navigator.languages[0] : navigator.language || 'en'
    );
  }

  const g = globalThis || window;
  if (!g?.__disableEdAppTokens) {
    incorporateToken(userToken, encapsulatedHeaders);
  }

  const options: RequestInit = {
    method: 'GET',
    credentials,
    headers: encapsulatedHeaders,
    signal: abortSignal
  };

  let fileName = '';

  return fetch(url, options)
    .then(async (response: Response) => {
      if (!response.ok) {
        // This allows Backbone code to know when the session is expired and
        // when it should show a modal to refresh a users session
        if (response.status === 401 && isClientSide) {
          const event = new window.CustomEvent('api-unauthorized', {
            detail: { url, response, options }
          });
          window.dispatchEvent(event);
        }

        // this is the default error
        let errorResponse: ErrorResponseType | ValidationErrorResponseType = {
          type: 'Error',
          message: DEFAULT_ERROR_MESSAGE,
          code: response.status === 401 ? ErrorCode.AuthenticationFailed : ErrorCode.NotSpecified,
          statusCode: response.status
        };

        try {
          const responseText = await response.text();
          const json = JSON.parse(responseText);

          if (json && typeof json === 'object') {
            errorResponse = json;
            // this error logic is coming from back-end
            // Hippo.Api -> ErrorHandlerMiddleware.cs
            errorResponse.type = 'validationErrors' in errorResponse ? 'ValidationError' : 'Error';
          } else {
            errorResponse.message = responseText;
          }
        } catch (err) {
          // ignore
        }

        throw EdException(
          `${options.method} request to ${url} resulted in a ${response.status} HTTP status code.`,
          response,
          response.status,
          url,
          options.body,
          errorResponse
        );
      }

      const contentDispositionHeader = response.headers
        .get('Content-Disposition')
        ?.split('filename=')[1]
        .split(';')[0];

      fileName = contentDispositionHeader ?? 'scorm-export.zip';

      return response.blob();
    })
    .then((data: Blob) => {
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(data);
      a.download = fileName;
      a.click();
    });
}

/**
 * Http response as stream.
 * Use the callback @onResponse to parse the response
 * @param onResponse: Callback for incoming response stream.
 */
export async function httpFetchStream<Payload = undefined>(
  method: FetchMethodType,
  url: string,
  userToken: TokenParameter,
  onResponse: (stream: ReadableStream) => void,
  bodyData?: Payload,
  credentials?: RequestCredentials,
  headers?: HeadersInit,
  locale?: string,
  uriEncodeData = false,
  abortSignal?: AbortSignal
): Promise<void> {
  const isClientSide = typeof window !== 'undefined' && typeof document !== 'undefined';

  const overridableHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    ...headers
  };
  const encapsulatedHeaders = new Headers(overridableHeaders);

  if (locale) {
    encapsulatedHeaders.set('locale', locale);
  } else {
    encapsulatedHeaders.set(
      'locale',
      !!navigator?.languages?.length ? navigator.languages[0] : navigator.language || 'en'
    );
  }

  const g = globalThis || window;
  if (!g?.__disableEdAppTokens) {
    incorporateToken(userToken, encapsulatedHeaders);
  }

  const options: RequestInit = {
    method,
    credentials,
    headers: encapsulatedHeaders,
    signal: abortSignal
  };

  if (bodyData) {
    if (method === 'GET') {
      const bodyParams = stripOutUndefinedAndNull(bodyData);
      const queryParams = qs.stringify(bodyParams, { encode: uriEncodeData });
      url += `?${queryParams}`;
    } else {
      options.body = JSON.stringify(
        Array.isArray(bodyData) ? bodyData : stripOutUndefinedAndNull(bodyData)
      );
    }
  }

  const response = await fetch(url, options);

  if (!response.ok) {
    // This allows Backbone code to know when the session is expired and
    // when it should show a modal to refresh a users session
    if (response.status === 401 && isClientSide) {
      const event = new window.CustomEvent('api-unauthorized', {
        detail: { url, response, options }
      });
      window.dispatchEvent(event);
    }

    // this is the default error
    let errorResponse: ErrorResponseType | ValidationErrorResponseType = {
      type: 'Error',
      message: DEFAULT_ERROR_MESSAGE,
      code: response.status === 401 ? ErrorCode.AuthenticationFailed : ErrorCode.NotSpecified,
      statusCode: response.status
    };

    try {
      const responseText = await response.text();
      const json = JSON.parse(responseText);

      if (json && typeof json === 'object') {
        errorResponse = json;
        // this error logic is coming from back-end
        // Hippo.Api -> ErrorHandlerMiddleware.cs
        errorResponse.type = 'validationErrors' in errorResponse ? 'ValidationError' : 'Error';
      } else {
        errorResponse.message = responseText;
      }
    } catch (err) {
      // ignore
    }

    throw EdException(
      `${options.method} request to ${url} resulted in a ${response.status} HTTP status code.`,
      response,
      response.status,
      url,
      options.body,
      errorResponse
    );
  }

  if (!response.body) {
    throw EdException(
      `${options.method} request to ${url} returned no response body.`,
      response,
      response.status,
      url,
      options.body
    );
  }

  onResponse(response.body);
}

type GetSignedUploadOptions = Pick<
  MediaUploadOptions,
  | 'signedUploadUrl'
  | 'mediaUploadApiKey'
  | 'userToken'
  | 'credentials'
  | 'headers'
  | 'uploadPreset'
  | 'folder'
>;

type GetSignedUploadResponse = {
  api_key: string;
  signature: string;
  url: string;
  uploadPreset: string;
  timestamp: string;
};

export class MediaUploadHelper {
  static uploadable(uri: string) {
    return !uri.startsWith('http');
  }

  /**
   * Get the signed parameters to be able to upload the media to the cloud provider.
   * The filename extension is used to determine the type of the file and what upload parameters to use.
   * @static
   * @param {string} filename A filename with extension
   * @param {GetSignedUploadOptions} options Request options
   * @returns Promise with a result containing parameters to send along with the upload request
   * @memberof MediaUploadHelper
   */
  static async getSignedUploadParams(filename: string, options: GetSignedUploadOptions) {
    const url = getUrlWithEncodedParams(options.signedUploadUrl, {
      filenameWithExtension: filename,
      uploadPreset: options.uploadPreset || '',
      folder: options.folder || ''
    });

    return httpFetch<GetSignedUploadResponse, undefined>(
      'GET',
      url,
      options.userToken,
      undefined,
      options.credentials,
      options.headers
    );
  }

  /**
   * Upload a media file, video or image, to the cloud provider.
   * @static
   * @param {MediaUploadOptions} options Upload options
   * @returns {Promise<string>} The secure url of the media
   * @memberof MediaUploadHelper
   */
  static async upload(options: MediaUploadOptions): Promise<UploadResponse> {
    // The request to Cloudinary requires a name in the file object
    const file = options.media;
    const { onProgress, mediaUploadApiKey } = options;

    // Get signed upload parameters
    const filename =
      typeof file !== 'string' ? file.name : file.split('/').pop()?.split('?')[0] || '';
    const signedParams = await this.getSignedUploadParams(filename, options);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('api_key', mediaUploadApiKey);
    if (options.folder) {
      formData.append('folder', options.folder);
    }
    if (!!options.uploadPreset || !!signedParams.uploadPreset) {
      formData.append('upload_preset', options.uploadPreset ?? signedParams.uploadPreset);
    }
    formData.append('timestamp', signedParams.timestamp);
    formData.append('signature', signedParams.signature);

    const response = await axios.post<MediaUploadResponse>(signedParams.url, formData, {
      onUploadProgress: (event: ProgressEvent) => {
        if (onProgress) {
          onProgress(this.getProgressPercentage(event), event);
        }
      },
      onDownloadProgress: (event: ProgressEvent) => {
        if (onProgress) {
          onProgress(this.getProgressPercentage(event), event);
        }
      }
    });

    return Promise.resolve({
      url: response.data.secure_url,
      streamUrl: !!response.data.eager ? response.data.eager[0]?.secure_url : undefined,
      resourceType: response.data.resource_type as 'image' | 'video',
      duration: response.data.duration
    });
  }

  static getProgressPercentage(event: ProgressEvent): number | undefined {
    return event.lengthComputable ? event.loaded / event.total : undefined;
  }
}

export function getEdErrorResponse(err: any): string {
  if (err instanceof Object && 'errorResponse' in err) {
    const error = (err as EdExceptionType).errorResponse;
    if (!error) {
      return DEFAULT_ERROR_MESSAGE;
    }

    switch (error.type) {
      case 'Error':
        return error.message;

      case 'ValidationError':
        // at the moment we are going to display one error at time for validation
        const fields = Object.keys(error.validationErrors);
        const firstField = fields[0];
        return error.validationErrors[firstField][0];

      default:
        throw Error(`Unknown EdErrorResponse type`);
    }
  }

  return err.message || err.toString();
}

/**
 * Extract error message and any validationErrors from the HippoError type
 * @param error The error from which the data's being extracted
 * @returns Derived error message and any validation errors
 */
export function extractError(error: HippoError | null): ExtractErrorResult {
  if (!error) {
    return {
      errorMsg: '',
      validationErrors: {},
      multipleErrors: []
    };
  }

  const errorResponse = (error as EdExceptionType)?.errorResponse;

  if (!errorResponse) {
    return {
      errorMsg: 'Something went wrong.',
      validationErrors: {},
      multipleErrors: []
    };
  }

  switch (errorResponse.type) {
    case 'ValidationError': {
      return {
        errorMsg: Object.values(errorResponse.validationErrors).join(', '),
        validationErrors: errorResponse.validationErrors,
        multipleErrors: []
      };
    }

    case 'Error':
    default: {
      return {
        errorMsg: errorResponse.message || 'Something went wrong.',
        validationErrors: {},
        multipleErrors: errorResponse.errors || []
      };
    }
  }
}

const statusCodesNotToRetry = [404, 403];

export const retry = (retryCount: number) => (failureCount: number, error: HippoError) => {
  if (error instanceof Error) {
    return failureCount <= retryCount;
  }

  return statusCodesNotToRetry.includes(error.statusCode) ? false : failureCount <= retryCount;
};
