import * as React from 'react';
import { AnimatePresence } from 'framer-motion';
import { Manager, Reference, Popper } from 'react-popper';
import { MotionCard, OptionsList } from './styled';
import type { DropdownProps } from './types';
import { getSpaceValue } from '@edapp/themes/src/common/space';
import * as ReactDOM from 'react-dom';
import { AnimationVariants, HEIGHT_ANIMATION_DURATION, variants } from './constants';

export const DROPDOWN_TEST_ID = 'dropdown';

export const Dropdown: React.FC<React.PropsWithChildren<DropdownProps>> = ({
  placement = 'bottom-start',
  matchTriggerWidth = true,
  testId = DROPDOWN_TEST_ID,
  closeOnOutsideClick = true,
  closeOnScroll = true,
  closeOnClickItem = false,
  keepOpenOnClickTrigger = true,
  trigger,
  isOpen,
  onClick,
  children,
  isDisabled,
  className,
  onAnimationComplete,
  offset = 'xs',
  menuPortalTarget,
  modifiers
}) => {
  const [isOpenState, setIsOpenState] = React.useState(!!isOpen);
  // Set a min-width on the dropdown list that matches the width of the trigger element
  const [minListWidth, setMinListWidth] = React.useState<number | null>(null);

  const triggerRef = React.useRef<any>(null);
  const optionsListRef = React.useRef<HTMLUListElement | null>(null);

  React.useEffect(() => setIsOpenState(!!isOpen), [isOpen]);

  const handleDocumentClick = React.useCallback(
    (clickEvent: MouseEvent) => {
      const clickTarget = clickEvent.target;

      if (
        clickTarget instanceof Node &&
        !(
          triggerRef.current?.contains(clickTarget) || optionsListRef.current?.contains(clickTarget)
        )
      ) {
        onClick?.(false);

        if (isOpen == null) {
          setIsOpenState(false);
        }
      }
    },
    [onClick, isOpen, triggerRef.current, optionsListRef.current]
  );

  React.useEffect(() => {
    if (!closeOnOutsideClick) {
      return;
    }
    document.body.addEventListener('click', handleDocumentClick);
    return () => document.body.removeEventListener('click', handleDocumentClick);
  }, [closeOnOutsideClick, handleDocumentClick]);

  const handleScroll = React.useCallback(() => {
    onClick?.(false);
    setIsOpenState(false);

    if (triggerRef.current instanceof HTMLElement) {
      triggerRef.current.blur();
    }
  }, [onClick]);

  React.useEffect(() => {
    // only add scroll listener when it opens
    if (!isOpenState) {
      return;
    }
    if (!closeOnScroll) {
      return;
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isOpenState, handleScroll]);

  const handleTriggerClick = React.useCallback(() => {
    if (isDisabled) {
      return;
    }

    if (keepOpenOnClickTrigger && !!isOpenState) {
      return; // do not toggle state
    }

    onClick?.(!isOpenState);

    if (isOpen == null) {
      setIsOpenState(!isOpenState);
    }
  }, [isDisabled, isOpenState, keepOpenOnClickTrigger, onClick, isOpen]);

  const cloneTrigger = React.useMemo(() => {
    return (
      <Reference>
        {({ ref }: { ref: React.Ref<HTMLElement> }) =>
          React.cloneElement(trigger, {
            ref: (node: HTMLElement) => {
              triggerRef.current = node;

              if (node && matchTriggerWidth) {
                setMinListWidth(node.clientWidth);
              }

              const { ref: originalRef } = trigger as any;
              if (typeof originalRef === 'function') {
                originalRef(node);
              }

              if (typeof ref === 'function') {
                ref(node);
              }
            },
            onClick: (clickEvent: React.MouseEvent) => {
              clickEvent.preventDefault();
              clickEvent.stopPropagation();
              trigger.props.onClick?.(clickEvent);
              handleTriggerClick();
            }
          })
        }
      </Reference>
    );
  }, [trigger, matchTriggerWidth, handleTriggerClick]);

  const renderPopperComponent = React.useCallback(
    () => (
      <AnimatePresence>
        {isOpenState && (
          <Popper
            placement={placement}
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, getSpaceValue(offset)]
                }
              },
              ...(modifiers ?? [])
            ]}
          >
            {({ ref, style }) => (
              <MotionCard
                initial={AnimationVariants.CLOSED}
                animate={AnimationVariants.OPEN}
                exit={AnimationVariants.CLOSED}
                transition={{
                  duration: HEIGHT_ANIMATION_DURATION,
                  clamp: true
                }}
                variants={variants}
                ref={ref}
                style={style}
                className={className}
                onAnimationComplete={onAnimationComplete}
              >
                <OptionsList ref={optionsListRef} data-testid={testId} minWidth={minListWidth}>
                  {React.Children.map(children, child => {
                    if (!React.isValidElement(child)) return child;
                    return React.cloneElement(child, {
                      onClick: (e: React.MouseEvent<HTMLDivElement>) => {
                        child.props.onClick?.(e);
                        closeOnClickItem && setIsOpenState(false);
                        closeOnClickItem && onClick?.(false);
                      }
                    } as React.DOMAttributes<HTMLElement>);
                  })}
                </OptionsList>
              </MotionCard>
            )}
          </Popper>
        )}
      </AnimatePresence>
    ),
    [
      isOpenState,
      placement,
      offset,
      className,
      onAnimationComplete,
      optionsListRef,
      testId,
      minListWidth,
      children
    ]
  );

  return (
    <Manager>
      {cloneTrigger}
      {menuPortalTarget
        ? ReactDOM.createPortal(renderPopperComponent(), menuPortalTarget)
        : renderPopperComponent()}
    </Manager>
  );
};
