import { select, takeLatest } from 'redux-saga/effects';

import type { RouteName } from '@maggie/core/router/types';
import type { LxStoreState } from '@maggie/store/types';

import { NavigationActionTypes } from './actions';
import { NavigationSelectors } from './selectors';

function* handleFallbackDefaultRoute() {
  const route: RouteName = yield select<LxStoreState>(NavigationSelectors.getFallbackRoute);
  return window.__router.navigate(route, {}, true);
}

export function* watchFallbackDefaultRoute() {
  yield takeLatest(NavigationActionTypes.FALLBACK_DEFAULT_ROUTE, handleFallbackDefaultRoute);
}
