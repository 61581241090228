import { createAction } from '@edapp/utils';

import type { CompletedSlide } from './types';

export enum BrainBoostActionTypes {
  FETCH_BRAIN_BOOST_SESSION = 'FETCH_BRAIN_BOOST_SESSION',
  FETCH_BRAIN_BOOST_SESSION_SUCCESS = 'FETCH_BRAIN_BOOST_SESSION_SUCCESS',
  FETCH_BRAIN_BOOST_SESSION_FAILURE = 'FETCH_BRAIN_BOOST_SESSION_FAILURE',
  FETCH_BRAIN_BOOST_SLIDES = 'FETCH_BRAIN_BOOST_SLIDES',
  FETCH_BRAIN_BOOST_SLIDES_SUCCESS = 'FETCH_BRAIN_BOOST_SLIDES_SUCCESS',
  FETCH_BRAIN_BOOST_SLIDES_FAILURE = 'FETCH_BRAIN_BOOST_SLIDES_FAILURE',
  GENERATE_BRAIN_BOOST = 'GENERATE_BRAIN_BOOST',
  GENERATE_BRAIN_BOOST_SUCCESS = 'GENERATE_BRAIN_BOOST_SUCCESS',
  GENERATE_BRAIN_BOOST_FAILURE = 'GENERATE_BRAIN_BOOST_FAILURE',
  LOADING_BRAIN_BOOST_SCORE = 'LOADING_BRAIN_BOOST_SCORE',
  SCORE_BRAIN_BOOST = 'SCORE_BRAIN_BOOST',
  SCORE_QUIZ_PLAY = 'SCORE_QUIZ_PLAY',
  LAUNCH_BRAIN_BOOST = 'LAUNCH_BRAIN_BOOST',
  BRAIN_BOOST_SLIDE_COMPLETE = 'BRAIN_BOOST_SLIDE_COMPLETE',
  CLEAR_COMPLETED_SLIDES = 'CLEAR_COMPLETED_SLIDES',
  CLOSE_BRAINBOOST_SUMMARY = 'CLOSE_BRAINBOOST_SUMMARY',
  SHOW_BRAINBOOST_TOAST = 'SHOW_BRAINBOOST_TOAST',
  HIDE_BRAINBOOST_TOAST = 'HIDE_BRAINBOOST_TOAST',
  CLEAR_BRAINBOOST_STREAKS = 'CLEAR_BRAINBOOST_STREAKS',

  OPEN_BRAINBOOST_THOMAS = 'OPEN_BRAINBOOST_THOMAS',
  CLOSE_BRAINBOOST_THOMAS = 'CLOSE_BRAINBOOST_THOMAS'
}

export const BrainBoostActions = {
  openBrainboostThomas: () => createAction(BrainBoostActionTypes.OPEN_BRAINBOOST_THOMAS, {}),
  closeBrainboostThomas: () => createAction(BrainBoostActionTypes.CLOSE_BRAINBOOST_THOMAS, {}),

  fetchBrainBoostSession: () => createAction(BrainBoostActionTypes.FETCH_BRAIN_BOOST_SESSION),
  fetchBrainBoostSlides: () => createAction(BrainBoostActionTypes.FETCH_BRAIN_BOOST_SLIDES),
  generateBrainBoost: () => createAction(BrainBoostActionTypes.GENERATE_BRAIN_BOOST),
  loadingBrainBoostScore: () => createAction(BrainBoostActionTypes.LOADING_BRAIN_BOOST_SCORE),

  scoreBrainBoost: ({ score, totalSlides }: { score: number; totalSlides: number }) =>
    createAction(BrainBoostActionTypes.SCORE_BRAIN_BOOST, { score, totalSlides }),
  scoreQuizPlay: ({ score, totalSlides }: { score: number; totalSlides: number }) =>
    createAction(BrainBoostActionTypes.SCORE_QUIZ_PLAY, { score, totalSlides }),

  launchBrainBoost: () => createAction(BrainBoostActionTypes.LAUNCH_BRAIN_BOOST),
  brainBoostSlideComplete: (slide: CompletedSlide) =>
    createAction(BrainBoostActionTypes.BRAIN_BOOST_SLIDE_COMPLETE, { slide }),
  clearCompletedSlide: () => createAction(BrainBoostActionTypes.CLEAR_COMPLETED_SLIDES),
  closeBrainBoostSummary: () => createAction(BrainBoostActionTypes.CLOSE_BRAINBOOST_SUMMARY),
  showToast: (isCorrect: boolean) =>
    createAction(BrainBoostActionTypes.SHOW_BRAINBOOST_TOAST, { isCorrect }),
  hideToast: () => createAction(BrainBoostActionTypes.HIDE_BRAINBOOST_TOAST),
  clearBrainBoostStreaks: () => createAction(BrainBoostActionTypes.CLEAR_BRAINBOOST_STREAKS)
};
